import React from "react"
import Link from "./link"

const Pagination = ({
  currentPage,
  totalPages,
  slug
}) => {
  return (
    <div className="container">
      <div className="row jcc">
        <div className="col col-lg-3">
          <nav>
            <ul className="pagination pb4">
              {currentPage > 1 &&
                <li className="">
                  {currentPage === 2 &&
                    <Link href={ `/${slug}` } aria-label="Previous">
                      <span className="fa fa-angle-left"></span>
                    </Link>
                  }
                  {currentPage > 2 &&
                    <Link href={ `/${slug}/page/${currentPage - 1}` } aria-label="Previous">
                      <span className="fa fa-angle-left"></span>
                    </Link>
                  }
                </li>
              }
              <li>
                {currentPage === 1 &&
                  <a className="current disabled page-number page-numbers current">1</a>
                }
                {currentPage !== 1 &&
                  <Link href={ `/${slug}` }>1</Link>
                }
              </li>
              {(currentPage - 2) > 2 &&
                <li>
                  <a className="current disabled dots">…</a>
                </li>
              }
              {(currentPage - 2) > 1 &&
                <li>
                  <Link href={ `/${slug}/page/${currentPage - 2}` }>{ currentPage - 2 }</Link>
                </li>
              }
              {(currentPage - 1) > 1 &&
                <li>
                  <Link href={ `/${slug}/page/${currentPage - 1}` }>{ currentPage - 1 }</Link>
                </li>
              }
              {(currentPage !== 1 && currentPage !== totalPages) &&
                <li>
                  <Link className="current disabled">{ currentPage }</Link>
                </li>
              }
              {(currentPage + 1) < totalPages &&
                <li>
                  <Link href={ `/${slug}/page/${currentPage + 1}` }>{ currentPage + 1 }</Link>
                </li>
              }
              {(currentPage + 2) < totalPages &&
                <li>
                  <Link href={ `/${slug}/page/${currentPage + 2}` }>{ currentPage + 2 }</Link>
                </li>
              }
              {(currentPage + 2) < (totalPages - 1) &&
                <li>
                  <a className="current disabled dots">…</a>
                </li>
              }
              <li>
                {currentPage === totalPages &&
                  <a className="current disabled page-number page-numbers current">{ totalPages }</a>
                }
                {currentPage !== totalPages &&
                  <Link href={ `/${slug}/page/${totalPages}` }>{ totalPages }</Link>
                }
              </li>
              {currentPage < totalPages &&
                <li className>
                  <Link href={ `/${slug}/page/${currentPage + 1}/` } aria-label="Next">
                    <span className="fa fa-angle-right" />
                  </Link>
                </li>
              }
            </ul>
          </nav>
        </div>
      </div>
    </div>
  )
}

export default Pagination
